import type { ContentName } from "@/react-app/api/elevate-api";
import { FILTER_SEPARATOR } from "@/react-app/constants";
import {
  RANGE_FILTER_MAX,
  RANGE_FILTER_MIN,
} from "@/react-components/Filter/FilterBar/constants";
import { FORCE_SHOW_ALL_OF_CAMPAIGN_QUERY_PARAMETER } from "@/react-components/Search/Constants";
import type { AVAILABILITY } from "@/react-components/Search/SearchFetchProductsHelper.types";
import {
  setPreferredStoresCookie,
  type PreferredStoreIds,
} from "@/react-utils/Cookie";
import { isEmpty } from "@xxl/common-utils";
import type { SortType } from "@xxl/product-search-api";
import { useSearchParams } from "next/navigation";
import { useRouter } from "next/router";
import { useCallback } from "react";
import type { SetFacetProps } from "react-app/src/components/Filter/FilterMenu/Filters/FilterAccordions/DistinctFilterAccordion/types";
import type { UrlParameterName } from "../constants";
import { URL_PARAMETERS } from "../constants";
import {
  deleteUrlParameter,
  removeUrlParametersForFacetsAndPages,
  setUrlParameter,
  setUrlParameters,
} from "./utils";
import { isTrue } from "@/utils/environment-variables";

export type Parameters = {
  [FORCE_SHOW_ALL_OF_CAMPAIGN_QUERY_PARAMETER]: string;
  categoryPath: string;
  query: string;
  page: string;
  sort?: SortType;
};

const EMPTY_PAGES_PARAMETER = {
  name: "pages",
  value: null,
} as const;

const useProductListParams = (shouldUseShallowRouting = false) => {
  const router = useRouter();
  const searchParams = useSearchParams();

  const paginate = (pageNumber: number) => {
    void setUrlParameter({
      name: URL_PARAMETERS.page.name,
      value: pageNumber.toString(),
      router,
      shallow: shouldUseShallowRouting,
    });
  };

  const removeAllFacets = ({
    longTailUrl: pathname,
  }: {
    longTailUrl: string | null;
  }) => {
    return removeUrlParametersForFacetsAndPages(
      router,
      pathname,
      shouldUseShallowRouting
    );
  };

  const sortBy = (strategy: string) => {
    void setUrlParameters({
      parameters: [
        { name: URL_PARAMETERS.sort.name, value: strategy },
        EMPTY_PAGES_PARAMETER,
      ],
      pathname: null,
      router,
      shallow: shouldUseShallowRouting,
    });
  };

  const setFacet = ({
    action,
    id,
    longTailUrl: pathname,
    values,
  }: SetFacetProps) => {
    const name = `${URL_PARAMETERS.facet.name}${id}` as UrlParameterName;
    if (
      (action === "remove" || action === "removeLongtail") &&
      isEmpty(values)
    ) {
      return deleteUrlParameter(
        name,
        router,
        pathname,
        shouldUseShallowRouting
      );
    }
    return setUrlParameters({
      parameters: [
        {
          name: name,
          value: values.join(FILTER_SEPARATOR),
        },
        EMPTY_PAGES_PARAMETER,
      ],
      pathname,
      router,
      shallow: shouldUseShallowRouting,
    });
  };

  const setRangeFacet = ({
    id,
    ...facet
  }: {
    id: string;
    max: number;
    min: number;
  }) => {
    const max = {
      name: `${URL_PARAMETERS.facet.name}${id}.${RANGE_FILTER_MAX}` as UrlParameterName,
      value: facet.max.toString(),
    };
    const min = {
      name: `${URL_PARAMETERS.facet.name}${id}.${RANGE_FILTER_MIN}` as UrlParameterName,
      value: facet.min.toString(),
    };
    return setUrlParameters({
      parameters: [max, min, EMPTY_PAGES_PARAMETER],
      pathname: null,
      router,
      shallow: shouldUseShallowRouting,
    });
  };

  const setTab = (tabId: ContentName) =>
    setUrlParameter({
      name: URL_PARAMETERS.tab.name,
      value: tabId,
      router,
      shallow: shouldUseShallowRouting,
    });

  const setCategoryPath = useCallback(
    (categoryPath: string[]) => {
      const legacyParameterThatShouldBeRemovedFromTheUrl = {
        name: URL_PARAMETERS.showAll.name,
        value: null,
      };
      const parameters = [
        {
          name: URL_PARAMETERS.categoryPath.name,
          value: categoryPath.toString(),
        },
        legacyParameterThatShouldBeRemovedFromTheUrl,
      ];
      void setUrlParameters({
        parameters,
        pathname: null,
        router,
        shallow: shouldUseShallowRouting,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      /* router https://github.com/vercel/next.js/discussions/29403 */
      shouldUseShallowRouting,
    ]
  );

  const setStoresAndAvailability = ({
    availability,
    selectedStoreIds,
    shallow = false,
  }: {
    availability: AVAILABILITY[];
    selectedStoreIds: PreferredStoreIds;
    shallow: boolean;
  }) => {
    setPreferredStoresCookie({
      availability,
      ids: selectedStoreIds,
    });

    void setUrlParameters({
      parameters: [EMPTY_PAGES_PARAMETER],
      pathname: null,
      router,
      shallow,
    });
  };

  const setShowAll = useCallback(
    ({ value, shallow }: { value: string; shallow: boolean }) => {
      void setUrlParameter({
        name: URL_PARAMETERS.showAll.name,
        value,
        router,
        shallow,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      /* router https://github.com/vercel/next.js/discussions/29403 */
    ]
  );

  const setQuery = useCallback(
    ({ shallow, value }: { shallow?: boolean; value: string }) => {
      void setUrlParameter({
        name: URL_PARAMETERS.query.name,
        value,
        router,
        shallow: shallow ?? shouldUseShallowRouting,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      /* router https://github.com/vercel/next.js/discussions/29403 */
      shouldUseShallowRouting,
    ]
  );

  const legacyShowAllParameter =
    searchParams.get(URL_PARAMETERS.showAll.name) ?? "";
  const availability =
    searchParams.get(URL_PARAMETERS.availability.name) ??
    URL_PARAMETERS.availability.default;
  const categoryPath =
    searchParams.get(URL_PARAMETERS.categoryPath.name) ??
    (isTrue(legacyShowAllParameter)
      ? FORCE_SHOW_ALL_OF_CAMPAIGN_QUERY_PARAMETER
      : URL_PARAMETERS.categoryPath.default);
  const page =
    searchParams.get(URL_PARAMETERS.page.name) ?? URL_PARAMETERS.page.default;
  const query =
    searchParams.get(URL_PARAMETERS.query.name) ?? URL_PARAMETERS.query.default;
  const selectedStores =
    searchParams.get(URL_PARAMETERS.selectedStores.name) ??
    URL_PARAMETERS.selectedStores.default;
  const sort = (searchParams.get(URL_PARAMETERS.sort.name) ??
    URL_PARAMETERS.sort.default) as SortType;

  return {
    paginate,
    parameters: {
      availability,
      categoryPath,
      page,
      query,
      selectedStores,
      sort,
    },
    removeAllFacets,
    setCategoryPath,
    setFacet,
    setQuery,
    setRangeFacet,
    setShowAll,
    setStoresAndAvailability,
    setTab,
    sortBy,
  };
};

export { useProductListParams };
