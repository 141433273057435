import React from "react";
import {
  BasicInfo,
  Brand,
  ColorsAndRatingContainer,
  CurrentPrice,
  HighlightedLabel,
  ImageContainer,
  Name,
  PriceWrapper,
  ProductCardContainer,
  ProductInfoContainer,
  ProductLink,
  StockStatus,
  StockStatuses,
} from "./Product.styled";
import { DEFAULT_GRID_VALUE } from "./constants";
import { Skeleton } from "react-app/src/components/Common";
import { showSalesPriceDisclaimer } from "react-app/src/utils/PriceDisplay/price-display";
import { useSharedData } from "react-app/src/contexts/SharedData";
import { useXxlMediaQuery } from "react-app/src/hooks/useXxlMediaQuery";

const ProductSkeleton = ({
  selectedColumnsNumber = DEFAULT_GRID_VALUE,
}: {
  selectedColumnsNumber: number;
}) => {
  const {
    data: { siteUid },
  } = useSharedData();
  const isLaptop = useXxlMediaQuery("LaptopMediaQuery");

  return (
    <ProductCardContainer
      columnAmount={selectedColumnsNumber}
      isFocused={false}
      isHoverable={isLaptop}
      role="listitem"
      showHighlightedLabel={true}
      showSalesPriceDisclaimer={showSalesPriceDisclaimer(siteUid)}
      showStoreStock={true}
    >
      <ProductLink>
        <ImageContainer
          columnAmount={selectedColumnsNumber}
          isFocused={false}
        />
        <ProductInfoContainer className="product-info-container">
          <BasicInfo columnAmount={selectedColumnsNumber}>
            <Brand
              data-testid="new-product-brand"
              columnAmount={selectedColumnsNumber}
            >
              <Skeleton width="30%" />
            </Brand>
            <Name>
              <Skeleton width="80%" />
            </Name>
          </BasicInfo>
          <PriceWrapper>
            <CurrentPrice
              columnAmount={selectedColumnsNumber}
              isDiscountPrice={false}
            >
              <Skeleton width="40%" />
            </CurrentPrice>
          </PriceWrapper>
          <HighlightedLabel isTransparent={true} />
          <ColorsAndRatingContainer hasColorsAndRating={true}>
            <Skeleton width="30%" />
          </ColorsAndRatingContainer>
          <StockStatuses
            columnAmount={selectedColumnsNumber}
            hasStoreStatus={false}
            isFocused={false}
          >
            {Array(2)
              .fill(null)
              .map((_, index) => (
                <StockStatus
                  key={`stock-status-skeleton-${index}`}
                  style={{ width: "100%" }}
                >
                  <Skeleton width="70%" />
                </StockStatus>
              ))}
          </StockStatuses>
        </ProductInfoContainer>
      </ProductLink>
    </ProductCardContainer>
  );
};

export { ProductSkeleton };
