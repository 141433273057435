import type { ProductListProps } from "@/components/ProductListV2/ProductList";
import { ProductList } from "@/components/ProductListV2/ProductList";
import type { FetchDataClientSide } from "@/hooks/useProductList/types";
import { ErrorBoundary } from "@/react-components/Common/ErrorBoundary/ErrorBoundary";
import type { NumberOfColumnsPerDeviceOnPlp } from "@/react-components/ProductList/hooks/useSelectedColumnsNumber/useSelectedColumnsNumber.types";
import type { AVAILABILITY } from "@/react-components/Search/SearchFetchProductsHelper.types";
import type { MultipleAvailabilitySelectorProps } from "@/react-components/Sort/AvailabilitySelector/MultipleAvailabilitySelector";
import type { PreferredStoreIds } from "@/react-utils/Cookie";
import type { ProductCardDataV2 } from "@/react-utils/ProductData/product-card-data-helper";
import { withErrorBoundary } from "@/react-utils/WithErrorBoundary/with-error-boundary";
import type {
  LongTailData,
  ProductListingSmallBannerData,
} from "@xxl/frontend-api";
import type { FacetData, SortOrderData } from "@xxl/product-search-api";
import { useSelectedColumnsNumber } from "react-app/src/components/ProductList/hooks/useSelectedColumnsNumber";
import type { WithPossibleAutoCorrect } from "../../ProductListV2/ProductList.helper";
import { ProductListItems } from "./ProductListItems";
import { NR_OF_PRODUCTS_PER_PAGE } from "@/components/ProductListV2/constants";
import { ProductSkeleton } from "@/react-components/Product/ProductSkeleton";
import isEmpty from "lodash/isEmpty";

export type SearchResultsProductListProps = WithPossibleAutoCorrect & {
  availability: AVAILABILITY[];
  categoryId: string | null;
  categoryPath?: string[];
  facets: FacetData[];
  fetchDataClientSide: FetchDataClientSide | null;
  isFetchingProducts: boolean;
  longTailFacets: LongTailData[] | null;
  longTailPattern: string | null;
  numberOfColumnsPerDeviceOnPlp: NumberOfColumnsPerDeviceOnPlp | null;
  productListingSmallBanner: ProductListingSmallBannerData[] | null;
  products: ProductCardDataV2[];
  selectedFilters: { [key: string]: string[] };
  sortOrderData: SortOrderData;
  storeIds: PreferredStoreIds;
  storesData: MultipleAvailabilitySelectorProps["storesData"];
  totalHits: number;
};

const SearchResultsProductList = ({
  autoCorrect,
  availability,
  categoryId,
  categoryPath,
  facets,
  fetchDataClientSide,
  isFetchingProducts,
  longTailFacets,
  longTailPattern,
  numberOfColumnsPerDeviceOnPlp,
  productListingSmallBanner,
  products,
  selectedFilters,
  sortOrderData,
  storeIds,
  storesData,
  totalHits,
}: SearchResultsProductListProps) => {
  const selectedColumnsNumber = useSelectedColumnsNumber({
    initialNumberOfColumnsPerDeviceOnPlp: numberOfColumnsPerDeviceOnPlp,
  });

  const props: ProductListProps = {
    autoCorrect,
    availability,
    categoryId,
    categoryPath,
    facets,
    isLoadingProducts: isFetchingProducts,
    longTailFacets,
    longTailPattern,
    fetchDataClientSide,
    selectedColumnsNumber,
    selectedFilters,
    sortOrderData,
    storesData,
    storeIds,
    totalHits,
  };

  if (isFetchingProducts && isEmpty(products)) {
    return (
      <ProductList
        {...props}
        isLoadingProducts={false /* show skeletons without loading state */}
      >
        {Array(NR_OF_PRODUCTS_PER_PAGE)
          .fill(null)
          .map((_, index) => (
            <li key={`skeleton-${index}`}>
              <ProductSkeleton selectedColumnsNumber={selectedColumnsNumber} />
            </li>
          ))}
      </ProductList>
    );
  }

  return (
    <ProductList {...props}>
      <ErrorBoundary>
        <ProductListItems
          productListingSmallBanner={productListingSmallBanner}
          products={products}
          selectedColumnsNumber={selectedColumnsNumber}
        />
      </ErrorBoundary>
    </ProductList>
  );
};

export default withErrorBoundary(SearchResultsProductList);
