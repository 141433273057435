import { QUERY_KEYS } from "@/components/Pdp/queries/queryKeys";
import type { FetchDataClientSide } from "@/hooks/useProductList/types";
import { useSessionSource } from "@/react-app/contexts/Session";
import { useSharedData } from "@/react-app/contexts/SharedData";
import type { RangeValue } from "@/react-components/Common/RangeSlider/RangeSlider.types";
import {
  RANGE_FILTER_MAX,
  RANGE_FILTER_MIN,
  stickyFiltersMarginSettings,
} from "@/react-components/Filter/FilterBar/constants";
import { getDistinctFilterItemHref } from "@/react-components/Filter/FilterHelper";
import type { AVAILABILITY } from "@/react-components/Search/SearchFetchProductsHelper.types";
import type { AvailabilityBarProps } from "@/react-components/Sort/AvailabilitySelector/AvailabilityBar";
import { AvailabilityBar } from "@/react-components/Sort/AvailabilitySelector/AvailabilityBar";
import type { MultipleAvailabilitySelectorProps } from "@/react-components/Sort/AvailabilitySelector/MultipleAvailabilitySelector";
import { type PreferredStoreIds } from "@/react-utils/Cookie";
import { useQuery } from "@tanstack/react-query";
import { hasNoValue, hasValue } from "@xxl/common-utils";
import type { LongTailData } from "@xxl/frontend-api";
import type { FacetData, SortOrderData } from "@xxl/product-search-api";
import { useStateValue } from "cotton-box-react";
import { useInView } from "framer-motion";
import isEqual from "lodash/isEqual";
import noop from "lodash/noop";
import { useRouter } from "next/router";
import {
  useCallback,
  useEffect,
  useRef,
  useState,
  type PropsWithChildren,
} from "react";
import { XxlStack } from "react-app/src/components/Common/XxlStack";
import { FilterBar } from "react-app/src/components/Filter/FilterBar/FilterBar";
import type { OnChangeFilterProps } from "react-app/src/components/Filter/FilterMenu/Filters/FilterAccordions/DistinctFilterAccordion/types";
import { ListWrapper } from "react-app/src/components/ProductList/ProductList.styled";
import { useTracking } from "react-app/src/contexts/Tracking";
import { containerMaxWidth } from "react-app/src/styles/xxl-theme";
import { Pagination } from "../Pagination";
import { pageStringToNumber } from "./ProductList.helper";
import { NR_OF_PRODUCTS_PER_PAGE, URL_PARAMETERS } from "./constants";
import { useProductListParams } from "./hooks/useProductListParams";

export type ProductListProps = {
  autoCorrect: string | null;
  availability: AVAILABILITY[];
  categoryId: string | null;
  facets: FacetData[] | null;
  fetchDataClientSide: FetchDataClientSide | null;
  isLoadingProducts: boolean;
  longTailFacets: LongTailData[] | null;
  longTailPattern: string | null;
  selectedColumnsNumber: number;
  selectedFilters: {
    [key: string]: string[];
  };
  sortOrderData: SortOrderData;
  storeIds: PreferredStoreIds;
  storesData: MultipleAvailabilitySelectorProps["storesData"];
  totalHits: number;
  categoryPath?: string[];
};

const ProductList = ({
  autoCorrect = null,
  availability: initialAvailability,
  categoryId,
  categoryPath = [],
  children,
  facets,
  fetchDataClientSide: optionalFetchDataClientSide,
  isLoadingProducts,
  longTailFacets,
  longTailPattern,
  selectedColumnsNumber,
  selectedFilters: selectedFiltersFromServer,
  sortOrderData,
  storeIds: initialStoreIds,
  storesData,
  totalHits,
}: PropsWithChildren<ProductListProps>) => {
  const fetchDataClientSide = optionalFetchDataClientSide ?? noop;
  const shouldUseShallowRouting = hasValue(optionalFetchDataClientSide);
  const {
    paginate,
    parameters,
    removeAllFacets,
    setFacet,
    setQuery,
    setRangeFacet,
    setStoresAndAvailability,
    sortBy,
  } = useProductListParams(shouldUseShallowRouting);

  const {
    data: {
      configuration: {
        frontendApi: { basePath },
      },
    },
  } = useSharedData();
  const trackers = useTracking();
  const pageCount = Math.ceil(totalHits / NR_OF_PRODUCTS_PER_PAGE);
  const page = pageStringToNumber(parameters.page);
  const { options = [], selected: initialSelectedSort = null } =
    sortOrderData ?? {};
  const scrollTarget = useRef<HTMLDivElement>(null);
  const availabilityBarRef = useRef(null);
  const IsAvailabilityBarInView = useInView(
    availabilityBarRef,
    stickyFiltersMarginSettings
  );
  const [availability, setAvailability] = useState(initialAvailability);
  const [selectedFilters, setSelectedFilters] = useState<{
    [key: string]: (string | number)[];
  }>(selectedFiltersFromServer);
  useEffect(() => {
    setSelectedFilters(selectedFiltersFromServer);
  }, [selectedFiltersFromServer]);
  const [selectedSort, setSelectedSort] = useState<
    SortOrderData["selected"] | null
  >(initialSelectedSort);
  useEffect(() => {
    setSelectedSort(sortOrderData?.selected ?? null);
  }, [sortOrderData]);
  const [storeIds, setStoreIds] = useState(initialStoreIds);
  const router = useRouter();
  const isLoggedIn = useStateValue(useSessionSource);

  useEffect(() => {
    router.beforePopState(({ as }) => {
      // Hack to trigger data refetch when navigating with browsers native back/forward because NextJS links in combination with router.replace(..) doesn't
      // https://xxlsports.atlassian.net/browse/XD-15987
      void router.replace(as);
      return true;
    });

    return () => router.beforePopState(() => true);
  }, [router]);

  const scrollToFilters = useCallback(() => {
    const { current } = scrollTarget;

    if (hasNoValue(current)) {
      return;
    }

    const top = current.offsetTop;
    window.requestAnimationFrame(() => window.scrollTo({ top }));
  }, [scrollTarget]);

  const handleChangeRangeFilterValue = useCallback(
    (id: string) =>
      ({ max, min }: RangeValue) => {
        setSelectedFilters((state) => {
          const values = {
            [`${id}.${RANGE_FILTER_MAX}`]: [max],
            [`${id}.${RANGE_FILTER_MIN}`]: [min],
          };
          const newState = {
            ...state,
            ...values,
          };
          fetchDataClientSide({
            availability,
            categoryId,
            page: URL_PARAMETERS.page.default,
            selectedFilters: newState,
            selectedSort,
            selectedStores: storeIds,
          });
          void setRangeFacet({ id, max, min });
          scrollToFilters();
          return newState;
        });
      },
    [
      availability,
      categoryId,
      fetchDataClientSide,
      scrollToFilters,
      selectedSort,
      setRangeFacet,
      storeIds,
    ]
  );

  const handleChangeFilterValue = useCallback(
    (props: OnChangeFilterProps) => {
      setSelectedFilters((state) => {
        if (
          props.action === "removeAll" ||
          props.action === "removeAllWithLongtailUrl"
        ) {
          const newState = {};
          fetchDataClientSide({
            availability,
            categoryId,
            page: URL_PARAMETERS.page.default,
            selectedFilters: newState,
            selectedSort,
            selectedStores: storeIds,
          });
          const { longTailUrl } = props;
          void removeAllFacets({ longTailUrl });
          scrollToFilters();
          return newState;
        }
        const { action, id } = props;
        const selectedFilter = hasValue(state[id]) ? state[id] : [];
        const [value] = props.values;
        const updatedFilters =
          action === "add" || action === "addLongtail"
            ? [...selectedFilter, value]
            : selectedFilter.filter((f) => f !== value);
        const newState = Object.fromEntries(
          Object.entries({
            ...state,
            [id]: updatedFilters,
          }).filter(([, v]) => hasValue(v))
        );
        fetchDataClientSide({
          availability,
          categoryId,
          page: URL_PARAMETERS.page.default,
          selectedFilters: newState,
          selectedSort,
          selectedStores: storeIds,
        });
        const longTailUrl =
          hasValue(longTailFacets) &&
          hasValue(longTailPattern) &&
          hasValue(props.values)
            ? getDistinctFilterItemHref(
                basePath,
                Object.entries(state).map(([attributeName, selected]) => ({
                  attributeName,
                  selected,
                  type: "distinct",
                })),
                props.action === "remove",
                props.id,
                props.values[0].toString(),
                longTailFacets,
                longTailPattern
              ).getUpdatedLongTailPath()
            : null;
        if (hasValue(longTailUrl)) {
          void setFacet({
            ...props,
            action: "addLongtail",
            longTailUrl,
            values: updatedFilters,
          });
        } else {
          void setFacet({ ...props, values: updatedFilters });
        }
        scrollToFilters();
        return newState;
      });
    },
    [
      availability,
      categoryId,
      basePath,
      fetchDataClientSide,
      longTailFacets,
      longTailPattern,
      removeAllFacets,
      scrollToFilters,
      selectedSort,
      setFacet,
      storeIds,
    ]
  );

  const onChangeSortOption = (value: string) => {
    const castedValue = value as SortOrderData["selected"];
    setSelectedSort(castedValue);
    trackers.sendSortChangeEvent({
      searchQuery: parameters.query,
      value,
    });
    fetchDataClientSide({
      availability,
      categoryId,
      page: URL_PARAMETERS.page.default,
      selectedFilters,
      selectedSort: castedValue,
      selectedStores: storeIds,
    });
    void sortBy(value);
    scrollToFilters();
  };

  const onAvailabilityChange: AvailabilityBarProps["onChange"] = (
    stores,
    _availability
  ) => {
    const ids: string[] = stores.reduce(
      (acc: string[], store) =>
        store.id === undefined ? acc : [...acc, store.id],
      []
    );

    setAvailability(_availability);
    setStoreIds(ids);
    setStoresAndAvailability({
      availability: _availability,
      selectedStoreIds: ids,
      shallow: shouldUseShallowRouting,
    });
    fetchDataClientSide({
      availability: _availability,
      categoryId,
      page: URL_PARAMETERS.page.default,
      selectedFilters,
      selectedSort,
      selectedStores: ids,
    });
  };

  const onPaginate = (pageNr: number) => {
    paginate(pageNr);
    fetchDataClientSide({
      availability,
      categoryId,
      page: pageNr,
      selectedFilters,
      selectedSort,
      selectedStores: storeIds,
    });
    scrollToFilters();
  };

  useEffect(() => {
    if (hasNoValue(autoCorrect)) {
      return;
    }
    setQuery({ value: autoCorrect, shallow: true });
  }, [autoCorrect, setQuery]);

  useEffect(() => {
    if (
      (hasNoValue(categoryPath) && hasNoValue(parameters.categoryPath)) ||
      isEqual(categoryPath.toString(), parameters.categoryPath)
    ) {
      return;
    }

    fetchDataClientSide({
      availability,
      categoryId,
      page: URL_PARAMETERS.page.default,
      selectedFilters,
      selectedSort,
      selectedStores: storeIds,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run on category id change
  }, [
    availability,
    categoryId,
    // categoryPath, Only run when categoryId change
    fetchDataClientSide,
    // parameters.categoryPath, Only run when categoryId change
    selectedFilters,
    selectedSort,
    storeIds,
  ]);

  useQuery({
    queryKey: [QUERY_KEYS.PRODUCT_LIST, isLoggedIn],
    queryFn: () =>
      fetchDataClientSide({
        availability,
        categoryId,
        page: parameters.page,
        selectedFilters,
        selectedSort,
        selectedStores: storeIds,
      }),
  });

  return (
    <XxlStack maxWidth={containerMaxWidth} ref={scrollTarget}>
      <FilterBar
        facets={facets}
        longTailFacets={longTailFacets}
        longTailPattern={longTailPattern}
        onChangeFilter={handleChangeFilterValue}
        onChangeRangeFilter={handleChangeRangeFilterValue}
        onChangeSortOption={onChangeSortOption}
        selectedColumnsNumber={selectedColumnsNumber}
        selectedFilters={selectedFilters}
        selectedSort={selectedSort}
        sortOptions={options}
        totalHits={totalHits}
        isStickyFilter={!IsAvailabilityBarInView}
        shouldAutomaticallyScrollToFilterBar={false}
      />
      <AvailabilityBar
        version={2}
        numberOfProducts={totalHits}
        onChange={onAvailabilityChange}
        selectedAvailability={availability}
        selectedColumnsNumber={selectedColumnsNumber}
        selectedStoreIds={storeIds}
        storesData={storesData}
        ref={availabilityBarRef}
      />
      <ListWrapper
        columnAmount={selectedColumnsNumber}
        isLoading={isLoadingProducts}
        data-testid="product-list"
      >
        {children}
      </ListWrapper>
      <Pagination onChange={onPaginate} page={page} pageCount={pageCount} />
    </XxlStack>
  );
};

export { ProductList };
